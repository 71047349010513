<template>
    <div class="jh-container">
        <div class="jh-ui-header">
            <h1>채팅 일자별 상담통계</h1>
            <div class="is-right">
            </div>
        </div>
        <div class="jh-search-form">
            <table>
                <colgroup>
                    <col width="55px">
                    <col width="300px">
                    <col width="80px">
                    <col width="120px">
            </colgroup>
            <tr>
                <th><label>조회기간</label></th>
                <td>
                    <div class="jh-cols">                        
                        <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="from" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
                            maxlength="10" @input="fromOnInput" @click:clear="dates[0] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                            </template>
                            <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :max="dates[1]" :day-format="mixin_getDate" @change="changeDate('start')"></v-date-picker>
                        </v-menu>
                        <span class="jh-unit">~</span>                       
                        <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="to" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndDate = false"
                            maxlength="10" @input="toOnInput" @click:clear="dates[1] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                            </template>
                            <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="changeDate('end')"></v-date-picker>
                        </v-menu>
                    </div>
                </td>
                <th><label>문의유형</label></th>
                <td>
                    <v-select class="jh-form" v-model="select_inqry_code_1" :items="inqry_code_1" item-value="CD" item-text="CD_NM" @change="changeInqryKey('001' , select_inqry_code_1, '1')"></v-select>
                </td>
                <td class="has-search">
                    <v-btn class="jh-btn is-search" @click="selectList">조회</v-btn>
                </td>                
                <!-- <td>
                    <v-select class="jh-form" v-model="select_inqry_code_2" :items="inqry_code_2" item-value="CD" item-text="CD_NM" @change="changeInqryKey('001' , select_inqry_code_2, '2')"></v-select>
                </td>
                <td>
                    <v-select class="jh-form" v-model="select_inqry_code_3" :items="inqry_code_3" item-value="CD" item-text="CD_NM"></v-select>
                </td> -->
            </tr>
            <!-- <tr>
                <th><label>조회요일</label></th>
                <td colspan="3">
                    <v-select
                        class="jh-form is-round has-badge"
                        single-line
                        multiple
                        v-model="CHK_NEWDAY"
                        :items="CHK_DAY"
                        :disabled="CHK_SHOW_DAY"

                        item-value="value"
                        item-text="text"
                        placeholder="선택"
                        @change="[chgChkDay(),chkNoDay()]"
                    >
                        <template v-slot:selection="{ item, index }">
                        <span class="jh-badge is-light">
                            {{ item.text }}
                            <template>
                            <v-btn class="jh-btn is-sm is-icon" v-show="dropItemRemove === true" @click.stop="removeDropDay(item)"><i class="jh-icon-close-xs"></i></v-btn>
                            </template>
                        </span>
                        </template>
                    </v-select>
                </td>
                <th><label>조회시간</label></th>
                <td colspan="4">
                    <div class="jh-cols">
                        <v-select
                        class="jh-form is-round has-badge"
                        single-line
                        multiple
                        v-model="CHK_NEWTIME"
                        :items="CHK_TIME"
                        :disabled="CHK_SHOW_TIME"                      
                        item-value="value"
                        item-text="text"
                        placeholder="선택"
                        @change="[chgChkTime(),chkNoTime()]"
                        >
                        <template v-slot:selection="{ item, index }">
                            <span class="jh-badge is-light" v-if="index < 14">
                            {{ item.text }}
                            <template>
                                <v-btn class="jh-btn is-sm is-icon" v-show="dropItemRemove === true" @click.stop="removeDropTime(item)"><i class="jh-icon-close-xs"></i></v-btn>
                            </template>
                            </span>
                            <span v-if="index === 13" class="is-multi-dot">...</span>
                        </template>
                        </v-select>
                        <v-btn class="jh-btn is-round is-main" @click="allClick">전체선택</v-btn>
                        <v-btn class="jh-btn is-round is-border-blue" @click="[allCloseClick(),chkNoTime()]">전체해제</v-btn>
                        <v-btn class="jh-btn is-round is-blue" @click="selectTime">업무시간선택</v-btn>
                        <v-btn class="jh-btn is-round is-border-blue" @click="selectTurn">선택반전</v-btn>
                    </div>
                </td>
                <td class="has-search">
                    <v-btn class="jh-btn is-search" @click="selectList">조회</v-btn>
                </td>
            </tr> -->

            </table>
        </div>
    
        <div class="jh-form-wrap">
            <div class="jh-ui-header">
              <h2>채팅 일자별 상담통계 리스트</h2>
              <div class="is-right">
                <v-btn class="jh-btn is-light" @click="downloadExcel('dayCnslTable', '채팅 일자별 상담통계', '일자별 통계')">엑셀다운로드</v-btn>
              </div>
            </div>
            <data-tables
            id="dayCnslTable"
            dataTableClass=""
            :data-table-options="dataTableOptions"
            :paginationOptions="paginationOptions"
            @click:row="rowClick"
            >
              <template v-slot:header>
                <thead class="v-data-table-header">
                  <tr>
                    <th rowspan="1" width="60px"><span>NO</span></th>
                    <th rowspan="1"><span>조회일자</span></th>
                    <th rowspan="1"><span>상담원수</span></th>
                    <th rowspan="1"><span>실제고객수</span></th>
                    <th rowspan="1"><span>순인입</span></th>
                    <th rowspan="1"><span>응답</span></th>
                    <th rowspan="1"><span>포기</span></th>
                    <th rowspan="1"><span>순고객포기</span></th>
                    <th rowspan="1"><span>시스템포기</span></th>
                    <th rowspan="1" width="100px"><span>비중(%)</span></th>
                  </tr>
                  <!-- <tr>
                    <th><span>순고객포기</span></th>
                    <th><span>시스템포기</span></th>
                  </tr> -->
                </thead>
              </template>
                <template slot="body.append" v-if="gridDataText.length > 0">
                    <tr class="jh-grid-footer is-sum">
                        <th colspan="2">합계</th>
                        <td align="center"> {{ mixin_sum_field( this.gridDataText, "sum_user" ) }} </td>
                        <td align="center"> {{ mixin_sum_field( this.gridDataText, "SUMINCOMING" ) }} </td>
                        <td align="center"> {{ mixin_sum_field( this.gridDataText, "NUMBER_CUSTOMER_REQUEST" ) }} </td>
                        <td align="center"> {{ mixin_sum_field( this.gridDataText, "NUMBER_COMPLETE" ) }} </td>
                        <td align="center"> {{ mixin_sum_field( this.gridDataText, "total_give_up" ) }} </td>
                        <td align="center"> {{ mixin_sum_field( this.gridDataText, "NUMBER_CUSTOMER_GIVE_UP" ) }} </td>
                        <td align="center"> {{ mixin_sum_field( this.gridDataText, "NUMBER_SYSTEM_GIVE_UP" ) }} </td>
                        <td align="center"> {{this.tot_ratio}} </td>
                    </tr>
                </template>
            </data-tables>
        </div>
        </div>
</template>
  
<script>
import api from "@/store/apiUtil.js";
import { mixin } from "@/mixin/mixin.js";
import moment from "moment";
import dataTables from "@/components/DataTables";
  
export default {
    name: "MENU_E020505", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    components: {
        dataTables
    },
    data() {
      return {
        menuStartDate: false,
        menuEndDate: false,
        menuStartMonth: false,
        menuEndMonth: false,
        dropItemRemove: true,
        from: moment().format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
      years: "",
        dates: [
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        ],
        fromMonth: moment().format("YYYY-MM"),
        toMonth: moment().format("YYYY-MM"),
        months: [
            new Date().toISOString().substr(0, 7),
            new Date().toISOString().substr(0, 7)
        ],
      CHK_SHOW_TIME: true,
      CHK_SHOW_DAY: true,
        CANT_DATE: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        gridDataHeaders: [
          { text: "No", align: "center", value: "index", width: "50px", sortable: false, },
          { text: "조회일자", value: "SEARCH_ORDER", align: "center", sortable: false },
          { text: "상담원수", value: "sum_user", align: "center", sortable: false },
          { text: "실제고객수", value: "SUMINCOMING", align: "center", sortable: false },
          { text: "인입건수", value: "NUMBER_CUSTOMER_REQUEST", align: "center", sortable: false },
          { text: "상담완료건수", value: "NUMBER_COMPLETE", align: "center", sortable: false },
          { text: "포기", value: "total_give_up", align: "center", sortable: false },
          { text: "고객포기", value: "NUMBER_CUSTOMER_GIVE_UP", align: "center", sortable: false },
          { text: "시스템포기", value: "NUMBER_SYSTEM_GIVE_UP", align: "center", sortable: false },
          { text: "응답비중(%)", value: "suc_ratio", align: "center", sortable: false },
        ],
        gridDataText: [],
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
        totalVisible: 10,

        
      dataTableOptions: {
          fixedHeader: true,
          hideDefaultFooter: true,
          hideDefaultHeader: true,
          height: '575px',
          itemKey: 'index',
          items: [],
          itemsPerPage: 50,
          noDataText: "조회된 결과가 없습니다.",
          page: 1,
          singleSelect: true,
          itemClass: 'isActiveRow',
      },
        paginationOptions: {
            totalVisible: 10
        },
  
        ASP_CUST_KEY: this.$store.getters["userStore/GE_USER_ROLE"].company,
  
      tot_ratio : 0, 
        /** 문의유형 3depth 변수 */
      inqry_code_1: [],
      select_inqry_code_1: "",
      inqry_code_2: [],
      select_inqry_code_2: "",
      inqry_code_3: [],
      select_inqry_code_3: "",


        SEARCH_TY: [
          {
            CD: "TIME",
            NM: "시간",
          },
          {
            CD: "DAY",
            NM: "일",
          },
          {
            CD: "MONTH",
            NM: "월",
          },
          {
            CD: "YEAR",
            NM: "년",
          },
          {
            CD: "DAY_OF_THE_WEEK",
            NM: "요일",
          },
        ],
  
        ASP_SENDER_KEY: [
          {
            CD: "",
            NM: "전체",
          },
        ],
  
        INQ_TYP_1: [
          {
            CD: "",
            CD_NM: "전체",
          },
        ],
  
        INQ_TYP_2: [
          {
            CD: "",
            CD_NM: "전체",
          },
        ],
  
        CHK_DAY: [
          {
            text: "일",
            value: "1",
          },
          {
            text: "월",
            value: "2",
          },
          {
            text: "화",
            value: "3",
          },
          {
            text: "수",
            value: "4",
          },
          {
            text: "목",
            value: "5",
          },
          {
            text: "금",
            value: "6",
          },
          {
            text: "토",
            value: "7",
          },
        ],
  
        CHK_TIME: [
          {
            text: "00시",
            selected: true,
            value: "00",
          },
          {
            text: "01시",
            selected: true,
            value: "01",
          },
          {
            text: "02시",
            selected: true,
            value: "02",
          },
          {
            text: "03시",
            selected: true,
            value: "03",
          },
          {
            text: "04시",
            selected: true,
            value: "04",
          },
          {
            text: "05시",
            selected: true,
            value: "05",
          },
          {
            text: "06시",
            selected: true,
            value: "06",
          },
          {
            text: "07시",
            selected: true,
            value: "07",
          },
          {
            text: "08시",
            selected: true,
            value: "08",
          },
          {
            text: "09시",
            selected: true,
            value: "09",
          },
          {
            text: "10시",
            selected: true,
            value: "10",
          },
          {
            text: "11시",
            selected: true,
            value: "11",
          },
          {
            text: "12시",
            selected: true,
            value: "12",
          },
          {
            text: "13시",
            selected: true,
            value: "13",
          },
          {
            text: "14시",
            selected: true,
            value: "14",
          },
          {
            text: "15시",
            selected: true,
            value: "15",
          },
          {
            text: "16시",
            selected: true,
            value: "16",
          },
          {
            text: "17시",
            selected: true,
            value: "17",
          },
          {
            text: "18시",
            selected: true,
            value: "18",
          },
          {
            text: "19시",
            selected: true,
            value: "19",
          },
          {
            text: "20시",
            selected: true,
            value: "20",
          },
          {
            text: "21시",
            selected: true,
            value: "21",
          },
          {
            text: "22시",
            selected: true,
            value: "22",
          },
          {
            text: "23시",
            selected: true,
            value: "23",
          },
        ],
  
        CHK_HOUR: false,
        ASP_NEWCUST_KEY: "001",
        SEARCH_NEWTY: "DAY",
        ASP_NEWSENDER_KEY: "",
        INQ_NEWTYP_1: {
          CD: "",
          NM: "전체",
        },
        INQ_NEWTYP_2: {
          CD: "",
          NM: "전체",
        },
  
        CHK_NEWDAY: ["1", "2", "3", "4", "5", "6", "7"],
        CHK_NEWTIME: [
          "00",
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
        ],
        
        ROW_DATA:{},
      };
    },
  
    created() {
        this.dataTableOptions.headers = this.gridDataHeaders;
        this.dataTableOptions.items = this.gridDataText;
    },
  async mounted(){
    this.chnASP()
    this.initInqry();

  },
  
    methods: {

       
    async initInqry() {
      //문의유형 대분류 조회
      this.inqry_code_1 = await this.mixin_inqry_code_get("001", "*");
      this.inqry_code_1.unshift({ CD_NM: "전체", CD: "" });
      this.inqry_code_2.unshift({ CD_NM: "전체", CD: "" });
      this.inqry_code_3.unshift({ CD_NM: "전체", CD: "" });
    },




      fromOnInput: function() {
        // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
        if (/^\d{4}-\d{2}-\d{2}$/.test(this.from) && moment(this.from).isValid()) {
          // 종료일자보다 이후를 입력했으면 종료일자로 변경
          if (moment(this.from).isAfter(this.dates[1])) {
            this.$nextTick(() => {
              this.from = this.dates[1];
            });
          }

          this.$nextTick(() => {
            this.dates[0] = this.from;
            // datepick 다시 랜더링
            this.menuStartDate = false;
            this.menuStartDate = true;
          });
        }
      },
      startDate(e) {
        this.from = e;
      },
      toOnInput: function() {
        // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
        if (/^\d{4}-\d{2}-\d{2}$/.test(this.to) && moment(this.to).isValid()) {
          // 시작일자보다 이전을 입력했으면 시작일자로 변경
          if (moment(this.to).isBefore(this.dates[0])) {
            this.$nextTick(() => {
              this.to = this.dates[0];
            });
          }

          this.$nextTick(() => {
            this.dates[1] = this.to;
            // datepick 다시 랜더링
            this.menuEndDate = false;
            this.menuEndDate = true;
          });
        }
      },
      endDate(e) {
        this.to = e;
      },
      fromMonthOnInput: function() {
        // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
        if (/^\d{4}-\d{2}$/.test(this.fromMonth) && moment(this.fromMonth).isValid()) {
          // 종료일자보다 이후를 입력했으면 종료일자로 변경
          if (moment(this.fromMonth).isAfter(this.months[1])) {
            this.$nextTick(() => {
              this.fromMonth = this.months[1];
            });
          }

          this.$nextTick(() => {
            this.months[0] = this.fromMonth;
            // datepick 다시 랜더링
            this.menuStartMonth = false;
            this.menuStartMonth = true;
          });
        }
      },
      startMonth(e) {
        this.fromMonth = e;
      },
      toMonthOnInput: function() {
        // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
        if (/^\d{4}-\d{2}$/.test(this.toMonth) && moment(this.toMonth).isValid()) {
          // 시작일자보다 이전을 입력했으면 시작일자로 변경
          if (moment(this.toMonth).isBefore(this.months[0])) {
            this.$nextTick(() => {
              this.toMonth = this.months[0];
            });
          }

          this.$nextTick(() => {
            this.months[1] = this.toMonth;
            // datepick 다시 랜더링
            this.menuEndMonth = false;
            this.menuEndMonth = true;
          });
        }
      },
      endMonth(e) {
        this.toMonth = e;
      },
      downloadExcel(tableId, fileNm, sheetNm){
      //number,date,time,string - n,d,t,s
      var exceltypeArr = {};
      exceltypeArr[0] = "n";
      exceltypeArr[1] = "s";
      exceltypeArr[2] = "n";
      exceltypeArr[3] = "n";
      exceltypeArr[4] = "n";
      exceltypeArr[5] = "n";
      exceltypeArr[6] = "n";
      exceltypeArr[7] = "n";
      exceltypeArr[8] = "n";
      exceltypeArr[9] = "n";

        
        //엑셀다운로드
        this.table_id   = tableId;
        this.file_name  = fileNm;
        this.sheet_name = sheetNm;
        this.mixin_common_exportExcel(exceltypeArr);
    },
      removeDropDay(item) {
        this.CHK_NEWDAY.splice((item.value-1), 1);
  
        let DayArray = this.CHK_NEWDAY;
        this.CHK_NEWDAY = [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ]
        for(let i = 0; i < DayArray.length; i++){
          if(DayArray[i] != "" || DayArray[i] != null){
            let m = parseInt(DayArray[i]);
            this.CHK_NEWDAY[m-1] = DayArray[i];
          }
        }
  
        this.chkNoDay();
      },
      removeDropTime(item) {
        this.CHK_NEWTIME.splice(parseInt(item.value), 1);
  
        let TimeArray = this.CHK_NEWTIME;
        this.CHK_NEWTIME = [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ]
        for(let i = 0; i < TimeArray.length; i++){
          if(TimeArray[i] != "" || TimeArray[i] != null){
            let m = parseInt(TimeArray[i]);
            this.CHK_NEWTIME[m] = TimeArray[i];
          }
        }
  
        this.chkNoTime();
      },
  
      //회사 구분에 콤보박스 조회
      async chnASP() {
        this.ASP_SENDER_KEY = [{ CD: "", NM: "전체"}];
        let sender = await this.mixin_channel_get(this.ASP_NEWCUST_KEY);
        this.ASP_SENDER_KEY = [...this.ASP_SENDER_KEY, ...sender];
      },
  
      //문의유형1 변경시 문의유형2 조회
      // chnInq() {
      //   this.getInqTy(this.INQ_NEWTYP_1.CD);
      // },
  
      //문의유형 콤보박스 조회
      // getInqTy(num) {
      //   if (num == "1") {
      //     this.INQ_TYP_1 = [{ CD: "", CD_NM: "전체" }];
      //     this.INQ_NEWTYP_1 = "";
      //     this.INQ_TYP_2 = [{ CD: "", CD_NM: "전체" }];
      //     this.INQ_NEWTYP_2 = "";
      //     var TYP_CD = "*";
      //   } else {
      //     this.INQ_TYP_2 = [{ CD: "", CD_NM: "전체" }];
      //     this.INQ_NEWTYP_2 = "";
      //     var TYP_CD = num;
      //   }
      //   api
      //     .post(
      //       "api/chat/setting/inqry-ty-manage/list", //api url입력
      //       {
      //         ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
      //         USE_YN: "Y",
      //         SPST_INQRY_TYP_CD: TYP_CD,
      //         ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
      //         ASP_CUST_KEY:
      //           this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
      //       },
      //       {
      //         head: {
      //           SERVICE: "chat.setting.inqry-ty-manage",
      //           METHOD: "list",
      //           TYPE: "BIZ_SERVICE",
      //         },
      //       }
      //     )
      //     .then((response) => {
      //       console.log(response.data.DATA);
      //       if (num == "1") {
      //         this.INQ_TYP_1 = [...this.INQ_TYP_1, ...response.data.DATA];
      //       } else {
      //         if (this.INQ_NEWTYP_1.CD == "") {
      //           this.INQ_TYP_2 = [{ CD: "", CD_NM: "전체" }];
      //           this.INQ_NEWTYP_2 = "";
      //         } else {
      //           this.INQ_TYP_2 = [...this.INQ_TYP_2, ...response.data.DATA];
      //         }
      //       }
      //     })
      //     .catch((err) => {
      //       alert(err);
      //     });
      // },
  
      allClick() {
        this.CHK_NEWTIME = [
          "00",
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
        ];
      },
  
      allCloseClick() {
        this.CHK_NEWTIME = [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ];
      },
  
      async selectTime() {
        if (this.ASP_NEWCUST_KEY == "" || this.ASP_NEWCUST_KEY == undefined) {
          this.showAlert("회사를 먼저 선택해주세요.");
          return;
        }
        await api
          .post(
            "api/statistics/chat/common/start-job-time/inqire", //api url입력
            {
              ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
              ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
              ASP_CUST_KEY:
                this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
            },
            {
              head: {
                SERVICE: "statistics.chat.common.start-job-time",
                METHOD: "inqire",
                TYPE: "BIZ_SERVICE",
              },
            }
          )
          .then((response) => {
            var ST_TIME = response.data.DATA[0].WORK_START_TIME;
  
            var ST_HOUR = ST_TIME.substr(0, 2);
            this.ST_JOB_TIME = ST_HOUR;
          })
          .catch((err) => {
            alert(err);
          });
  
        await api
          .post(
            "api/statistics/chat/common/end-job-time/inqire", //api url입력
            {
              ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
              ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
              ASP_CUST_KEY:
                this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
            },
            {
              head: {
                SERVICE: "statistics.chat.common.end-job-time",
                METHOD: "inqire",
                TYPE: "BIZ_SERVICE",
              },
            }
          )
          .then((response) => {
            var END_TIME = response.data.DATA[0].WORK_END_TIME;
  
            var END_HOUR = END_TIME.substr(0, 2);
            this.END_JOB_TIME = END_HOUR;
          })
          .catch((err) => {
            alert(err);
          });
  
        this.CHK_NEWTIME = [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ];
  
        for (
          var i = parseInt(this.ST_JOB_TIME);
          i <= parseInt(this.END_JOB_TIME);
          i++
        ) {
          if (i < 10) {
            let DB_TIME = "0" + i;
            this.CHK_NEWTIME[i] = DB_TIME;
          } else {
            this.CHK_NEWTIME[i] = i + "";
          }
        }
      },
  
      selectTurn() {
        var NEWTIME = [];
  
        for (var i = 0; i < 24; i++) {
          NEWTIME[i] = this.CHK_NEWTIME[i];
          if (i < 10) {
            if (NEWTIME[i] != "") {
              NEWTIME[i] = "";
            } else if (NEWTIME[i] == "") {
              let DB_TIME = "0" + i;
              NEWTIME[i] = DB_TIME;
            }
          } else {
            if (NEWTIME[i] != "") {
              NEWTIME[i] = "";
            } else if (NEWTIME[i] == "") {
              NEWTIME[i] = i + "";
            }
          }
        }
        this.CHK_NEWTIME = [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ];
        this.CHK_NEWTIME = NEWTIME;
      },
  
      selectList() {
        if (this.ASP_NEWCUST_KEY == "" || this.ASP_NEWCUST_KEY == undefined) {
          this.showAlert("회사를 먼저 선택해주세요.");
          return;
        }
        let DT1 = this.dates[0];
        let DT1_Array = DT1.split("-");
        var YEAR1 = this.years;
        var MONTH1 = DT1_Array[0] + DT1_Array[1];
        var ST_DATE = DT1_Array[0] + DT1_Array[1] + DT1_Array[2];
  
        let DT2 = this.dates[1];
        let DT2_Array = DT2.split("-");
        var MONTH2 = DT2_Array[0] + DT2_Array[1] + "31";
        var END_DATE = DT2_Array[0] + DT2_Array[1] + DT2_Array[2];

        if (this.SEARCH_NEWTY == "MONTH"){
        ST_DATE = MONTH1
        END_DATE = MONTH2
      }else if (this.SEARCH_NEWTY == "YEAR"){
        if (this.years == "" || this.years == undefined) {
          this.showAlert("조회 년도를 입력해주세요.");
          return;
        }
        ST_DATE = YEAR1 + "0101"
        END_DATE = YEAR1 + "1231"
      }


        api
          .post(
            "api/statistics/chat/counsel-by-date/inqire", //api url입력
            {
              ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
              SEARCH_TYPE: this.SEARCH_NEWTY,
              SEARCH_FROM: ST_DATE,
              SEARCH_TO: END_DATE,
              SEARCH_MONTH_FROM: MONTH1,
              SEARCH_MONTH_TO: MONTH2,
              SEARCH_YEAR: YEAR1,
            CHK_DAY_OF_THE_WEEK_1: this.CHK_NEWDAY[0]-1,
            CHK_DAY_OF_THE_WEEK_2: this.CHK_NEWDAY[1]-1,
            CHK_DAY_OF_THE_WEEK_3: this.CHK_NEWDAY[2]-1,
            CHK_DAY_OF_THE_WEEK_4: this.CHK_NEWDAY[3]-1,
            CHK_DAY_OF_THE_WEEK_5: this.CHK_NEWDAY[4]-1,
            CHK_DAY_OF_THE_WEEK_6: this.CHK_NEWDAY[5]-1,
            CHK_DAY_OF_THE_WEEK_7: this.CHK_NEWDAY[6]-1,
              SEARCH_TIME_0: this.CHK_NEWTIME[0],
              SEARCH_TIME_1: this.CHK_NEWTIME[1],
              SEARCH_TIME_2: this.CHK_NEWTIME[2],
              SEARCH_TIME_3: this.CHK_NEWTIME[3],
              SEARCH_TIME_4: this.CHK_NEWTIME[4],
              SEARCH_TIME_5: this.CHK_NEWTIME[5],
              SEARCH_TIME_6: this.CHK_NEWTIME[6],
              SEARCH_TIME_7: this.CHK_NEWTIME[7],
              SEARCH_TIME_8: this.CHK_NEWTIME[8],
              SEARCH_TIME_9: this.CHK_NEWTIME[9],
              SEARCH_TIME_10: this.CHK_NEWTIME[10],
              SEARCH_TIME_11: this.CHK_NEWTIME[11],
              SEARCH_TIME_12: this.CHK_NEWTIME[12],
              SEARCH_TIME_13: this.CHK_NEWTIME[13],
              SEARCH_TIME_14: this.CHK_NEWTIME[14],
              SEARCH_TIME_15: this.CHK_NEWTIME[15],
              SEARCH_TIME_16: this.CHK_NEWTIME[16],
              SEARCH_TIME_17: this.CHK_NEWTIME[17],
              SEARCH_TIME_18: this.CHK_NEWTIME[18],
              SEARCH_TIME_19: this.CHK_NEWTIME[19],
              SEARCH_TIME_20: this.CHK_NEWTIME[20],
              SEARCH_TIME_21: this.CHK_NEWTIME[21],
              SEARCH_TIME_22: this.CHK_NEWTIME[22],
              SEARCH_TIME_23: this.CHK_NEWTIME[23],
            INQRY_TYP_CD_1LEVEL: this.select_inqry_code_1,
            INQRY_TYP_CD_2LEVEL: this.select_inqry_code_2,
            INQRY_TYP_CD_3LEVEL: this.select_inqry_code_3,
              ASP_SENDER_KEY: this.ASP_NEWSENDER_KEY,

              ASP_USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
              ASP_CUST_KEY:
                this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD,
            },
            {
              head: {
                SERVICE: "statistics.chat.counsel-by-date",
                METHOD: "inqire",
                TYPE: "BIZ_SERVICE",
                ROW_CNT: "200",
                PAGES_CNT: "1",
              },
            }
          )
          .then((response) => {
            

            this.gridDataText = response.data.DATA;
            for (var i = 0; i < this.gridDataText.length; i++) {
              this.gridDataText[i]["index"] = i + 1;

              this.gridDataText[i].suc_ratio = (Number(this.gridDataText[i].suc_ratio)).toFixed(2);
            }
            var tot_ncr = parseInt(this.mixin_sum_field( this.gridDataText, "NUMBER_CUSTOMER_REQUEST"));
            var tot_nc = parseInt(this.mixin_sum_field( this.gridDataText, "NUMBER_COMPLETE"));

            // var tot_ncr = mixin_sum_field(this.gridDataText, "NUMBER_CUSTOMER_REQUEST")
            // var tot_nc = mixin_sum_field(this.gridDataText, "NUMBER_COMPLETE")

            this.tot_ratio = tot_nc / tot_ncr * 100
            this.tot_ratio = (Number(this.tot_ratio)).toFixed(2)

            if(tot_ncr == 0 || tot_nc == 0){
              this.tot_ratio = "0.00"
            }            

            this.dataTableOptions.items = this.gridDataText;

            this.page = 1;
          })
          .catch((err) => {
            alert(err);
          });
      },
  
      //Table row 클릭이벤트
      rowClick(item, row) {
        this.ROW_DATA = item;
        console.log("item",item);
      },
  
      isActiveRow(item){
        // const activeClass = (item.SEARCH_PERIOD_01 === this.ROW_DATA.SEARCH_PERIOD_01 ? (item.SEARCH_PERIOD_02 === this.ROW_DATA.SEARCH_PERIOD_02 ? 'is-active' : '') : '')
        return activeClass;
      },
  
      showAlert(msg) {
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: msg,
          iconClass: "jh-alert-icon is-warning",
          type: "default",
        });
      },
  
    //문의유형 중/소분류 조회
    async changeInqryKey(asp_cust_key, spst_inqry_typ_cd, data_type) {
      //data_type: 1  -> 대분류 변경시 중분류 조회
      //data_type: 2  -> 중분류 변경시 소분류 조회

      if (data_type === "1") {
        //대분류 변경시 중소분류 선택값 초기화
        this.select_inqry_code_2 = "";
        this.select_inqry_code_3 = "";
        this.inqry_code_2 = await this.mixin_inqry_code_get(
          asp_cust_key,
          spst_inqry_typ_cd,
          ""
        );
        this.inqry_code_2.unshift({ CD_NM: "전체", CD: "" });
        this.inqry_code_3.unshift({ CD_NM: "전체", CD: "" });
      } else if (data_type === "2") {
        //중분류 변경시 소분류 선택값 초기화
        this.select_inqry_code_3 = "";
        this.inqry_code_3 = await this.mixin_inqry_code_get(
          asp_cust_key,
          spst_inqry_typ_cd,
          ""
        );
        this.inqry_code_3.unshift({ CD_NM: "전체", CD: "" });
      }
    },

  
      chgChkTime(){
        let TimeArray = this.CHK_NEWTIME;
        this.CHK_NEWTIME = [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ]
        for(let i = 0; i < TimeArray.length; i++){
          if(TimeArray[i] != "" || TimeArray[i] != null){
            let m = parseInt(TimeArray[i]);
            this.CHK_NEWTIME[m] = TimeArray[i];
          }
        }
       this.CHK_NEWTIME.splice(24,1);
      },
  
      chgChkDay(){
        let DayArray = this.CHK_NEWDAY;
        this.CHK_NEWDAY = [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ]
        for(let i = 0; i < DayArray.length; i++){
          if(DayArray[i] != "" || DayArray[i] != null){
            let m = parseInt(DayArray[i])-1;
            this.CHK_NEWDAY[m] = DayArray[i];
          }
        }
       this.CHK_NEWDAY.splice(7,1);
      },
  
      chkInqTY(){
        if (this.SEARCH_NEWTY == "TIME"){
        this.CHK_SHOW_TIME = false
        this.CHK_SHOW_DAY = true
          let TIME_CNT = 0;
          for (let i = 0; i<this.CHK_NEWTIME.length;i++){
            if (this.CHK_NEWTIME[i] != ""){
              TIME_CNT = TIME_CNT + 1;
            }
          }
          if (TIME_CNT == 0){
            this.showAlert("조회 유형이 [시간]일 때<br>시간 조회조건이 하나 이상 필요합니다.<br><br>자동으로 [09시]를 선택합니다.");
            this.CHK_NEWTIME = [
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "09",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
            ]
          }
        } else if (this.SEARCH_NEWTY == "DAY_OF_THE_WEEK"){
        this.CHK_SHOW_TIME = true
        this.CHK_SHOW_DAY = false

          let DAY_CNT = 0;
          for (let i = 0; i<this.CHK_NEWDAY.length;i++){
            if (this.CHK_NEWDAY[i] != ""){
              DAY_CNT = DAY_CNT + 1;
            }
          }
          if (DAY_CNT == 0){
            this.showAlert("조회 유형이 [요일]일 때<br>요일 조회조건이 하나 이상 필요합니다.<br><br>자동으로 [월요일]를 선택합니다.");
            this.CHK_NEWDAY = [
              "",
              "2",
              "",
              "",
              "",
              "",
              "",
            ]
          }
        }else {
        this.CHK_SHOW_TIME = true
        this.CHK_SHOW_DAY = true
      }
      },
  
      chkNoDay(){
        let DAY_CNT = 0;
        for (let i = 0; i<this.CHK_NEWDAY.length;i++){
          if (this.CHK_NEWDAY[i] != ""){
            DAY_CNT = DAY_CNT + 1;
          }
        }
        if (DAY_CNT == 0){
          if (this.SEARCH_NEWTY == "DAY_OF_THE_WEEK"){
            this.showAlert("조회 유형이 [요일]일 때<br>요일 조회조건이 하나 이상 필요합니다.<br><br>자동으로 [월요일]를 선택합니다.");
            this.CHK_NEWDAY = [
              "",
              "2",
              "",
              "",
              "",
              "",
              "",
            ]
          }
        }
      },
  
      chkNoTime(){
        let TIME_CNT = 0;
        for (let i = 0; i<this.CHK_NEWTIME.length;i++){
          if (this.CHK_NEWTIME[i] != ""){
            TIME_CNT = TIME_CNT + 1;
          }
        }
        if (TIME_CNT == 0){
          if (this.SEARCH_NEWTY == "TIME"){
            this.showAlert("조회 유형이 [시간]일 때<br>시간 조회조건이 하나 이상 필요합니다.<br><br>자동으로 [09시]를 선택합니다.");
            this.CHK_NEWTIME = [
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "09",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
            ]
          }
        }
      },
      changeDate(type) {
      if(
        moment(this.dates[0]).isBefore(moment(new Date()).subtract(30, 'days').format('YYYY-MM-DD')) ||
        moment(this.dates[1]).isAfter(moment(new Date()).subtract(0, 'days').format('YYYY-MM-DD'))
      ){
        this.common_alert('검색 기간은 최근 1개월까지 선택 가능합니다.');
        const date = moment().format("YYYY-MM-DD");
        this.dates[0] = date;
        this.dates[1] = date;
        this.from = date;
        this.to = date;
        return;
      }

      if(type == 'start') {
        if (moment(this.dates[0]).isBefore(moment(this.dates[1]).subtract(30, 'days').format('YYYY-MM-DD'))) {
          this.common_alert('검색 기간은 최대 1개월까지 선택 가능합니다.');
          const date = moment().format("YYYY-MM-DD");
          this.dates[0] = date;
          this.dates[1] = date;
          this.from = date;
          this.to = date;
          return;
        } else {
          this.from = this.dates[0];
        }
      } else if (type == 'end') {
        if (moment(this.dates[1]).isAfter(moment(this.dates[0]).add(30, 'days').format('YYYY-MM-DD'))) {
          this.common_alert('검색 기간은 최대 1개월까지 선택 가능합니다.');
          const date = moment().format("YYYY-MM-DD");
          this.dates[0] = date;
          this.dates[1] = date;
          this.from = date;
          this.to = date;
          return;
        } else {
          this.to = this.dates[1];
        }
      }
    },
    },
  
    computed: {
      dateRangeText() {
        var newStartDate = this.dates[0];
        var newEndDate = this.dates[1];
        return newStartDate + " ~ " + newEndDate;
      },
    },
  };
  </script>
  
  <style></style>
  